import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from './global-constants';

@Injectable({
  providedIn: 'root',
})
export class BbApiService {
  // protocol: string;
  apiUrl: string;
  endpoints: Array<string> = [
    GlobalConstants.BB_AUTH_LOGIN,
    GlobalConstants.BB_AUTH_LOGOUT,
    GlobalConstants.BB_GET_SELLER_REQUESTS,
    GlobalConstants.BB_GET_PROPERTIES,
    GlobalConstants.BB_GET_USERS,
    GlobalConstants.BB_BUYER_SIGNUP,
    GlobalConstants.BB_SELLER_SIGNUP,
    GlobalConstants.BB_GET_ALL_BUYERS,
    GlobalConstants.BB_GET_ALL_SELLERS,
    GlobalConstants.BB_GET_BUYER_DETAILS,
    GlobalConstants.BB_GET_SELLER_DETAILS,
    GlobalConstants.BB_PROPERTY,
    GlobalConstants.BB_UPLOAD_FILE,
    GlobalConstants.BB_SELLER_REQUEST_ACTION,
    GlobalConstants.BB_SELLER_CREATE,
    GlobalConstants.BB_SELLER_UPDATE,
    GlobalConstants.BB_BUYER_UPDATE,
    GlobalConstants.BB_USER_UPDATE_PASSWORD,
    GlobalConstants.BB_USER_RESET_PASSWORD,
    GlobalConstants.BB_GET_BUYER_PROFILE,
    GlobalConstants.BB_USER_CHECK_EXISTING,
    GlobalConstants.BB_BUYER_PARTICIPATE,
    GlobalConstants.BB_GET_PROPERTY_WITH_DETAILS,
    GlobalConstants.BB_SELLER_PROPERTY_ACTION,
    GlobalConstants.BB_GET_ALL_PAR,
    GlobalConstants.BB_GET_ALL_PROPERTY_PAR_DETAILS,
    GlobalConstants.BB_GET_ALL_PROPERTY_AUCTIONS,
    GlobalConstants.BB_DOWNLOAD_ATTACHMENT,
    GlobalConstants.BB_GET_AUCTION_BY_PROPERTY,
    GlobalConstants.BB_UPDATE_AUCTION_AMOUNT,
    GlobalConstants.BB_DOWNLOAD_FILE_BY_DOCID_NAME,
    GlobalConstants.GET_ALL_EMAIL_TEMPLATE_CATEGORIES,
    GlobalConstants.GET_EMAIL_BY_CATEGORY,
    GlobalConstants.GET_EMAIL_TEMAPLATE_BY_ID,
    GlobalConstants.UPDATE_EMAIL_TEMPLATE,
    GlobalConstants.GET_EMAIL_EMAIL_BRANDING,
    GlobalConstants.UPDATE_EMAIL_EMAIL_BRANDING,
    GlobalConstants.BB_USER_FORGOT_PASSWORD,
    GlobalConstants.GET_EMAIL_BUFFER,
    GlobalConstants.BB_GET_SELLER_WITH_ATTACHMENTS,
    GlobalConstants.PROPERTY_AUCTION_HISTORY,
    GlobalConstants.BB_GET_BROKER_DETAILS,
    GlobalConstants.BB_PROPERTY_NEAR_BY_LOCATION,
    GlobalConstants.GET_ALL_SMS_TEMPLATE_CATEGORIES,
    GlobalConstants.GET_SMS_BY_CATEGORY,
    GlobalConstants.GET_SMS_TEMAPLATE_BY_ID,
    GlobalConstants.UPDATE_SMS_TEMPLATE,
    GlobalConstants.GET_EMAIL_SMS_BRANDING,
    GlobalConstants.UPDATE_SMS_BRANDING,
    GlobalConstants.GET_SMS_BUFFER,
    GlobalConstants.GET_SMS_TEMPLATES_CATEGORY,
    GlobalConstants.BB_PROPERTY_LEAD_BUYER,
  ];
  constructor() {
    // this.protocol = environment.apiUrl;
    this.apiUrl = environment.apiUrl;
  }
  /**
   * return respective url based on paramenter
   * @param url resquesting api url
   */
  getUrl(url: string) {
    //check url
    if (this.endpoints.indexOf(url) > -1) {
      return this.apiUrl + url;
    } else return '';
  }
}
